import { styled as styling } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { FontSize } from "../../../../../assets/font";
import Color from "../../../../../assets/colors";
import CloseIcon from "@mui/icons-material/Close";

export const ModalContainer = styling("div")(({ theme }) => ({
  padding: "40px 30px",
  position: "relative",
}));

export const TextContainer = styling("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: "10px",
}));

export const Text = styling("span")((props) => ({
  fontFamily: "Inter",
  fontSize:
    props.$text == "title"
      ? "14px"
      : props.$text == "subtitle"
      ? "16px"
      : props.$text == "message" || props.$text == "messageBold"
      ? "14px"
      : "12px",
  fontWeight:
    props.$text == "title"
      ? "400"
      : props.$text == "subtitle" || props.$text == "messageBold"
      ? "700"
      : "400",
  letterSpacing: props.$text == "title" && "1.5px",
}));

export const DialogStyled = styling(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "30px",
    maxWidth: "600px",
    width: "85%",
  },
}));

export const ButtonContainer = styling("div")({
  display: "flex",
  justifyContent: "center",
});

export const SolidPurpleButton = styling(Button)(({ theme }) => ({
  padding: "8px 40px",
  fontSize: "12px",
  fontWeight: FontSize.bold,
  borderRadius: "30px",
  background: Color.hiredlyPurple,
  color: Color.white,
  boxShadow: "none",
  textTransform: "capitalize",
}));

export const TitleContainer = styling("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const CloseIconStyled = styling(CloseIcon)(({ theme }) => ({
  color: Color.secondaryGrey,
  position: "absolute",
  top: "20px",
  right: "25px",

  "&:hover": {
    cursor: "pointer",
  },
}));
