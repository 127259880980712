import { useRouter } from "next/router";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import buildOwnResume from "../../../../../assets/lotties/resume/build-own-resume.json";
import { sendTrackingEvent } from "../../../../../helpers/tracking_management";
import {
  updateCreateResumePopupStatus,
  updateUserProfileDrawerIndex,
} from "../../../../../redux/actions/user_action";
import { HeightSpacer } from "../../../../shared/Spacer/styles";
import {
  ButtonContainer,
  CloseIconStyled,
  DialogStyled,
  ModalContainer,
  SolidPurpleButton,
  Text,
  TextContainer,
  TitleContainer,
} from "./styles";

function CreateResumePopup(props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const isCreateResumePopupOpen = useSelector(
    (state) => state.user.isCreateResumeOpen
  );

  const viewingJob = useSelector((state) => state.jobs.job);

  const onClosingPopup = (origin) => {
    if (origin && origin === "button") {
      sendTrackingEvent({
        event: "CE_click-create-resume-popup",
        item: "close_button",
        "job-id": viewingJob?.id ?? null,
        "company-id": viewingJob?.company?.id ?? null,
        "job-title": viewingJob?.title ?? null,
      });
    }
    dispatch(updateCreateResumePopupStatus(false));
  };

  const onBringMeThere = () => {
    sendTrackingEvent({
      event: "CE_click-create-resume-popup",
      item: "bring_me_there",
      "job-id": viewingJob?.id ?? null,
      "company-id": viewingJob?.company?.id ?? null,
      "job-title": viewingJob?.title ?? null,
    });
    dispatch(updateUserProfileDrawerIndex(2));
    dispatch(updateCreateResumePopupStatus(false));

    router.push("/profile#my-resume", undefined, { shallow: true });
  };

  return (
    <DialogStyled open={isCreateResumePopupOpen} onClose={onClosingPopup}>
      <ModalContainer>
        <TitleContainer>
          <Text $text={"title"}>CREATE YOUR RESUME</Text>
          <CloseIconStyled
            onClick={() => {
              onClosingPopup("button");
            }}
          />
        </TitleContainer>
        <HeightSpacer $height={"16px"} />
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: buildOwnResume,
          }}
          width={180}
        />
        <TextContainer>
          <Text $text={"subtitle"}>Create your resume to apply for jobs.</Text>
          <HeightSpacer $height={"16px"} />
          <Text $text={"message"}>
            You will need a resume to apply for jobs!
          </Text>
          <div>
            <Text $text={"messageBold"}> Upload </Text>
            <Text $text={"message"}>your resume or</Text>
            <Text $text={"messageBold"}> Create </Text>
            <Text $text={"message"}>one with your Hiredly user profile!</Text>
          </div>
        </TextContainer>
        <HeightSpacer $height={"50px"} />
        <ButtonContainer>
          <SolidPurpleButton onClick={onBringMeThere}>
            Bring me there!
          </SolidPurpleButton>
        </ButtonContainer>
      </ModalContainer>
    </DialogStyled>
  );
}

export default CreateResumePopup;
